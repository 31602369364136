import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { StaticImage as Img } from 'gatsby-plugin-image'
import Content from './style'
import SectionTitle from './Components/SectionTitle'
export default function ContentSection(){
  return(
    <Content>
      <Container>
        <Content.InnerWrapper>
          <Row className="align-items-center justify-content-center">
            <Col xs="12" className="col-lg-4 col-md-10 col-xs-11 text-center text-lg-start">
              {/* Section Title */}
              <SectionTitle 
                    subTitle="Наша миссия" 
                    title="У нас огромные цели"
                    text="Как мы собираемся достичь наших целей"
                    titleProps={{mb:"30px"}}
                    subTitleProps={{mb:"25px"}}
                    />
                    {/* <Content.Button background="#ff5722" rounded={true} mt="25px">
                      Learn More
                    </Content.Button> */}
            </Col>
            <Col xs="12" className="col-xxl-5 col-lg-4 col-md-6 col-xs-9">
              <Content.Image mt="30px" mb="30px" mbMD="0" mtMD="0">
                <Img src="../../../assets/image/home-agency/content-1-l5-img.png" alt="content" layout="fullWidth" placeholder="blurred"/>
              </Content.Image>
            </Col>
            <Col xs="12" className="col-xl-3 col-lg-4 col-md-6 col-xs-9 order-3">
              <Content.WidgetsWrapper mb="-10px" mtMD="40px" mtLG="0" mrXS="70px" mrMD="30px" mrLG="0">
                {/* Single Services */}
                <Content.Widget pb="10px">
                  <Content.WidgetTitle>Ищем талантливых</Content.WidgetTitle>
                  <Content.WidgetText>Мы ищем талантливую молодежь, которая хочет научиться решать проблемы.</Content.WidgetText>
                </Content.Widget>
                {/*/ .Single Services */}
                {/* Single Services */}
                <Content.Widget pb="10px">
                  <Content.WidgetTitle>Обучаем</Content.WidgetTitle>
                  <Content.WidgetText>Мы предоставляем обучение на высшем уровне. Мы используем инновационные методики обучения, чтобы обеспечить наших студентов самыми необходимыми навыками решения проблем.</Content.WidgetText>
                </Content.Widget>
                {/*/ .Single Services */}
                {/* Single Services */}
                <Content.Widget pb="10px">
                  <Content.WidgetTitle>Покажем путь к успеху</Content.WidgetTitle>
                  <Content.WidgetText>Мы помогаем нашим ученикам достигать своих целей. Независимо от того, хотят ли они устроиться на работу или запустить стартап, мы их поддерживаем.</Content.WidgetText>
                </Content.Widget>
                {/*/ .Single Services */}
              </Content.WidgetsWrapper>
            </Col>
          </Row>
        </Content.InnerWrapper>
      </Container>
    </Content>
    
    )
}