import { Link } from '~components'
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Images } from '~data'
import Footer from "./style"
export default function FooterSection(){
return(
    <Footer>
    <Container>
        <Footer.Box pbXL="95px">
        <Row>
            <Col xs="12" className="col-lg-3 col-md-7 col-xs-9">
            <Footer.Widgets className="footer-widgets footer-widgets--l7">
                {/* Brand Logo*/}
                <Footer.Box mb="30px">
                    <Link  to="#">
                        <img src={Images.FooterSix.LogoBlack} alt="logo" />
                    </Link>
                </Footer.Box>
                <Footer.Text mb="36px">
                    Единственный центр программистов<br className="d-none d-xl-block" /> в Самарканде, где предлагают курсы,<br className="d-none d-xl-block" /> ивенты, соревнования и стажировки.</Footer.Text>
                    <Footer.SocialShare>
                        <Footer.SocialShareItem>
                            <a href="https://www.instagram.com/nextgen.uz/">
                            <i className="fab fa-instagram" />
                            </a>
                        </Footer.SocialShareItem>
                        <Footer.SocialShareItem>
                            <a href="https://www.facebook.com/nextgen.uz">
                            <i className="fab fa-facebook-square" />
                            </a>
                        </Footer.SocialShareItem>
                        <Footer.SocialShareItem>
                            <a href="https://t.me/nextgen_uz">
                            <i className="fab fa-telegram" />
                            </a>
                        </Footer.SocialShareItem>
                        <Footer.SocialShareItem>
                            <a href="https://linkedin.com/company/nextgen-uz">
                            <i className="fab fa-linkedin" />
                            </a>
                        </Footer.SocialShareItem>
                    </Footer.SocialShare>
            </Footer.Widgets>
            </Col>
            <Col xs="12" className="col-lg-9">
            <Row>
                <Col xs="12" className="col-lg-4 col-xs-6">
                    <Footer.Widgets>
                        <Footer.Title>Контакты</Footer.Title>
                        <Footer.Address className="widgets-address">
                            <Footer.AddressItem>
                                <i className="fa fa-map-marker-alt" />
                                <span>Самарканд, ул Мухаммад<br className="d-block" />
                                Аль-Хорезми, 113 <br className="d-block" />
                                Бизнес центр, эт.5<br className="d-block" /></span>
                            </Footer.AddressItem>
                            <Footer.AddressItem>
                                <i className="fa fa-phone-alt" />
                                
                                <a href="tel:+998956807777"><span>Телефон:</span><br className="d-block" />+998 (95) 680-77-77 </a>
                            </Footer.AddressItem>
                            <Footer.AddressItem>
                                <i className="fa fa-envelope" />
                                <a href="mailto:info@nextgen.uz">
                                <span className="d-block">Э-почта
                                </span>info@nextgen.uz</a>
                            </Footer.AddressItem>
                        </Footer.Address>
                    </Footer.Widgets>
                </Col>
                <Col xs="12" className="col-lg-4 col-xs-6">
                <Footer.Widgets>
                    <Footer.Title>Школа</Footer.Title>
                    <Footer.List>
                        <Footer.ListItems>
                            <a href="/about/about-us">О нас</a>
                        </Footer.ListItems>
                        {/* <Footer.ListItems>
                            <a href="/about/testimonials-1">Отзывы</a>
                        </Footer.ListItems> */}
                         <Footer.ListItems>
                            <a href="/about/contact">Контакты</a>
                        </Footer.ListItems>
                        {/*<Footer.ListItems>
                            <a href="#">Rider</a>
                        </Footer.ListItems>
                        <Footer.ListItems>
                            <a href="#">Contact</a>
                        </Footer.ListItems> */}
                    </Footer.List>
                </Footer.Widgets>
                </Col>
                <Col xs="12" className="col-lg-4 col-md-6 col-xs-9">
                <Footer.Widgets>
                    <Footer.Title>Блог</Footer.Title>
                    <Footer.Text>Подпишитесь на наш телеграм канал для еженедельного полезного контента.</Footer.Text>
                    
                    <Footer.Newsletter>
                        <form action="https://t.me/nextgen_uz">
                            <Footer.NewsletterBtn backgroundColor="#ff5722" hoverTextColor="#fff" textColor="#fff" sizeX="150px" sizeY="56px" type="submit">Подписаться</Footer.NewsletterBtn>
                        </form>
                    </Footer.Newsletter>
                    
                </Footer.Widgets>
                </Col>
            </Row>
            </Col>
        </Row>
        </Footer.Box>
        <Footer.Copyright>
        <Footer.CopyrightText>© 2021 Next Generation. All Rights Reserved</Footer.CopyrightText>
        </Footer.Copyright>
    </Container>
    </Footer>
)
}