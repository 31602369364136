import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Feature from './style'
import { StaticImage as Img } from 'gatsby-plugin-image'
import SectionTitle from './Components/SectionTitle'
import CounterBlock from './Components/CounterBlock'
export default function FeatureSection(){
return(
<Feature backgroundColor="#f3f4f6">
  <Container>
    {/* Section Title */}
    <Row>
      <Col className="col-xl-8 col-lg-10">
        <Feature.Box>
        <SectionTitle 
                subTitle="О нас" 
                title="Почему наша школа уникальная?"
                titleProps={{mb:"33px"}}
                subTitleProps={{mb:"20px"}}
                />
        </Feature.Box>
      </Col>
    </Row>
    <Row>
      <Col xs="12" className="col-lg-6 col-md-12 mb-5 mb-lg-7">
        <Feature.Box>
          <Feature.Text mrLG="30px">
            Nextgen является частью Diamond Solutions - одна из лучших 
            ИТ компаний Самарканда. Наша уникальная особенность в том, что мы предлагаем 
            трудоустройство после успешного завершения курсов в нашу
            команду. Также мы поможем вам научиться работать фрилансером.
          </Feature.Text>
        </Feature.Box>
      </Col>
      <Col xs="12" className="col-lg-6 col-md-12 mb-5 mb-lg-7">
        <Feature.Box>
          <Feature.Text mrLG="30px">
          Все из наших преподавателей одновременно работают с такими 
          организациями как Юнеско, МВД РУз, и частные компании, и 
          разрабатывают software для них. У всех из них имеется минимум
          5 лет коммерческого опыта.
          </Feature.Text>
        </Feature.Box>
      </Col>
    </Row>
    {/*/ .Section Title */}
    {/* Feature Content */}
    <Row className="align-items-center justify-content-center">
      <Col xs="12" className="col-lg-6 col-md-12">
        <Feature.Image mb="30px">
          <Img className="w-100" src="../../../assets/image/it-services/feature-l3-image.png" alt="it-service" layout="fullWidth" placeholder="blurred"/>
        </Feature.Image>
      </Col>
      <Col className="col-lg-6 col-md-12">
        <Feature.Box>
          {/* Feature List */}
          <Feature.Contentlist mbLG="65px" mb="30px">
            <Feature.List>Профессиональные преподаватели</Feature.List>
            <Feature.List>Интенсивные уроки (до 9 студентов в одной группе)</Feature.List>
            <Feature.List>Трудоустройство после успешного завершения курсов</Feature.List>
          </Feature.Contentlist>
          {/*/ .Feature List */}
          <CounterBlock mt="50px"/>
        </Feature.Box>
      </Col>
    </Row>
    {/*/ .Feature Content */}
  </Container>
</Feature>

)
}