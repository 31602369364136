import React from "react";
import { PageWrapper } from "~components/Core";
import BreadCrumbSection from "~sections/about/BreadCrumb";
import AboutSection from '~sections/about/about'
import FeatureSection from '~sections/about/Feature'
import ContentSection from '~sections/about/Content'
import TeamSection from '~sections/about/Team'
import PromoSection from '~sections/about/Promo'
import FooterSection from "~sections/about/Footer";

export default function About() {
  return (
    <PageWrapper innerPage={true}>
        <BreadCrumbSection title="О нас" text="Next Generation - это единственный центр программистов в Самарканде, где предлагают курсы, ивенты, соревнования и стажировки."/>
        <AboutSection/>
        <FeatureSection/>
        <ContentSection/>
        <TeamSection/>
        <PromoSection/>
        <FooterSection/>
    </PageWrapper>
  )
}
