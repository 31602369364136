import React from 'react'
import Counter from './style'
 
export default function CounterBlock({...rest}){
return(
<Counter {...rest}>
    <Counter.Wrapper>
    <Counter.Single>
        <Counter.Title as="h3" fontColor="#fff"><span className="counter">50+</span></Counter.Title>
        <Counter.Text fontColor="#ffffffb3">Студентов</Counter.Text>
    </Counter.Single>
    <Counter.Single>
        <Counter.Title as="h3" fontColor="#fff"><span className="counter">84</span>%+</Counter.Title>
        <Counter.Text fontColor="#ffffffb3">Успешных выпускников</Counter.Text>
    </Counter.Single>
    <Counter.Single>
        <Counter.Title as="h3" fontColor="#fff"><span className="counter">$470</span>+</Counter.Title>
        <Counter.Text fontColor="#ffffffb3">Средняя зарплата выпускников</Counter.Text>
    </Counter.Single>
    </Counter.Wrapper>
</Counter>

)
}